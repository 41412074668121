<template>
    <div class="dropdown pipeline-action" v-on-click-away="hideLanguage">
        <a href="javascript:void(0)"  @click="togglePage()" class="button white-button dropdown-toggle text-capitalize" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ $t(selectedType) }}
        </a>
        <div class="dropdown-menu dropdown-menu-left" :class="{ 'show' : listOpen }">
            <a class="dropdown-item text-capitalize" v-for="type in jobTypes" href="javascript:void(0)" @click="changeJob(type.routeName)">
                {{ $t(type.name) }}
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                listOpen: false,
                jobTypes: [
                    {
                        name : `published jobs`,
                        routeName : 'jobs.published'
                    },
                    {
                        name : `draft jobs`,
                        routeName : 'jobs.draft'
                    },
                    {
                        name : `archived jobs`,
                        routeName : 'jobs.archived'
                    }
                ]
            }

        },
        methods : {
            changeJob(routeName) {
              this.$router.push({
                  name : routeName
              });
            },

            togglePage() {
                this.listOpen = !this.listOpen;
            },
            hideLanguage () {
                this.listOpen = false;

            }
        },

        computed : {
            selectedType(){
                let currentType = this.jobTypes.filter((type) => type.routeName == this.$route.name );
                return currentType[0].name;
            },
        },

        mounted () {
            this.popupItem = this.$el;
        },
    }
</script>
